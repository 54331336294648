<template>
	<el-form :model="form" label-width="105px" class="form">
		<div class="subTit"><i class="el-icon-caret-bottom" />组织架构</div>
		<div class="center">
			<el-form-item label="关键词:">
				<el-input v-model="form.keywords" />
			</el-form-item>
			<el-form-item label="描述:">
				<el-input type="textarea" :rows="3" v-model="form.description" />
			</el-form-item>
			<el-form-item label="内容">
				<tinyMce :content="form.content" v-model="form.content" :change.sync="form.content" />
				<!-- <vue-tinymce v-model="form.content" :setting="setting" :setup="setup" /> -->
			</el-form-item>
		</div>
		<div class="submit-btn" @click="handleSubmit">保存</div>
	</el-form>
</template>

<script>
	import tinyMce from '@/components/plug/tinyMce_shop'
	export default {
		data() {
			return {
				form: {
					keywords: '',
					description: '',
					content: ''
				},
				imageUrl: '',
			}
		},
		created() {
			this.$axios.post('/getSellerLanmuContent', {
				lm_id: 4
			}).then(data => {
				for (let i in this.form) {
					if (data[i]) {
						this.form[i] = data[i]
					}
				}
				this.imageUrl = data.logoUrl
			})
		},
		methods: {
			handleAvatarSuccess(res, file) {
				if (res.error == 'success') {
					this.form.logo = res.imgurl
					this.imageUrl = URL.createObjectURL(file.raw);
				}
			},
			handleSubmit() {
				this.form.lm_id = 4
				this.form.seller_id = this.$store.state.SELLER_ID
				this.$axios.post('/setSellerLanmuContent', this.form).then(data => {
					if (data && data.code == 400005) {
						this.$message.error(data.msg)
						return
					}
					this.$message.success('设置成功')
				})
			}
		},
		components: {
			tinyMce
		}
	}
</script>

<style scoped>
	.form>>>.el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>
